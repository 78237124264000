<template lang="pug">
div
  //- v-skeleton-loader(v-if="loading" type='card' :height='60' :width='388')
  div(v-if="!loading") 
     app-section.align-center(v-if="type=='StringField'")
       app-text {{ field.name }}
       app-text-field.mt-3.my-0.field-item(v-model="fieldValue.value" :backgroundColor="'#F4F4F4'" rounded hide-details :placeholder="field.defaultValue" test-id="field-string")
     app-section.align-center(v-if="type=='TextField'")
       app-textarea.mt-3.my-0(v-model="fieldValue.value" :rounded="true" hide-details noResize :label="field.name" :placeholder="field.name" outlined test-id="field-text")
     app-section.align-center(v-if="type=='ListField' || type=='ModelField'")
       app-text {{ field.name }}
       app-select.mt-3.field-item(:value="fieldValue.value" :backgroundColor="'#F4F4F4'" rounded :items="fieldData" item-text="name" return-object @change="onChange")

     div(v-if="type == 'ClassField' || type == 'AttrField' || type == 'ConditionField'")
       app-section
          app-text(style="color: black") {{field.name}}
          app-text(test-id="field-value") {{fieldValue.value}}
          app-button.mt-3(small action @click="showCondition" test-id="field-button-select") {{ $t('button.choose')}}

       simple-condition(v-model="dialog.condition" :axisFilter="axisFilter" :simpleView="true" @created="afterCreateCondition")

     app-section(v-if="type=='DateField'")
      app-text {{ field.name }}
      v-menu(
        ref="date"
        :close-on-content-click="false" :return-value.sync="date"
        transition="scale-transition" offset-y min-width="auto"
      )
        template(v-slot:activator="{ on }")
          v-text-field.form-field.align-center.field-form-date(
            v-model="date" 
            prepend-icon="mdi-calendar" readonly
            dense
            v-on="on" light
            :hideDetails="true"
            test-id="field-calendar"
          )
        v-date-picker(v-model="date" first-day-of-week=1 no-title scrollable @input="$refs.date.save(date)" @change="onChange")
        //-v-col(v-if="field.extraCheck" cols="auto")
      div.ml-2(v-if="field.extraCheck")
        app-text-field( rounded :backgroundColor="'#F4F4F4'" v-model="time" dense type="time" hide-details @change="onChange")


</template>

<script>
import { api } from "@/api"
import SimpleCondition from '@/components/project/panel/left/components/logicGroup/Condition'

export default {
  components: { SimpleCondition },
  props: {
    field: null,
    valueList: {
      type: Map
    }
  },

  data () {
    const defaultForm = Object.freeze({
          uuid: null,
          field: { "uuid" : this.field.uuid },
          alias: null,
          value: null,
          valueId: null
        })

    return {
      fieldValue: Object.assign({}, defaultForm),
      fieldData: null,
      date: null,
      time: '',
      dialog: {
        condition: false
      },
      loading: true
    }
  },

  mounted () {
    this.fieldValue.alias = this.field.alias
    this.valueList.set(this.fieldValue.field.uuid, this.fieldValue)

    if(this.field.defaultValue && (this.type=='StringField' || this.type=='TextField'))
      this.fieldValue.value = this.field.defaultValue

    if(this.field.defaultValue && (this.type=='ClassField' || this.type=='AttrField'))
      this.fieldValue.value = this.field.defaultValue

    if(this.type=='DateField'){
      if(this.field.defaultValue != null && this.field.defaultValue.indexOf(' ') != -1){
        this.date = this.field.defaultValue.split(' ')[0]
        this.fieldValue.value = this.field.defaultValue.split(' ')[0]
        this.time = this.field.defaultValue.split(' ')[1]
        if (this.field.extraCheck) this.fieldValue.value += " " + this.time
      } else {
        this.date = this.field.defaultValue
        this.fieldValue.value = this.field.defaultValue
      }
    } 


    if(this.field.type=='ListField' || this.field.type=='ModelField'){
      api.forms.fieldPocess(this.field.uuid).then(data => {
        this.fieldValue.value = this.field.defaultValue
        this.fieldData = data
        this.loading = false
      })
    } else
      this.loading = false

  },

  computed: {
    type() {
      return this.field.type
    },
    activeClass() {
      return "font-weight: 500; color: "+this.$vuetify.theme.themes.dark.accent.base
    },
    axisFilter () {
      if(this.type == "AttrField" || this.type == 'ConditionField') return ['ATTRIBUTE','USERPROP']
      else return ['CLASS']
    }
  },

  methods: {
    itemName (item) {
      return (item.name && item.name!='') ? item.name : (item.title && item.title!='') ? item.title : (item.classTitle && item.classTitle!='') ? item.classTitle : this.$t('section.elementTree.name')
    },

    showCondition() {
      this.dialog.condition = true
    }, 

    afterCreateCondition(cond) {
      this.fieldValue.value = cond.title
      this.fieldValue.valueId = cond.operandUuid
      this.dialog.condition = false
    },

    onChange(val) {
      switch(this.type) {
        case 'DateField': 
          this.fieldValue.value = this.date ? this.date : ''
          if (this.field.extraCheck) this.fieldValue.value += " " + this.time
          break
        default:
          this.fieldValue.value = val.name || val.title
          this.fieldValue.valueId = val.uuid
      }
    }
  }
}
</script>

<style lang="sass">
  .v-input.field-form-date
    .v-input__control
      .v-input__slot
        padding: 0px !important

</style>

<style lang="scss" scoped>
.h-200px {
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.form-field {
  padding: 4px !important;
  border-radius: 2px !important;
  margin: 0px;
}

.field-item {
  width: 100%;
  border-radius: 2px !important;
}
</style >

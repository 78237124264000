<template lang="pug">
div( style= "height:300px" )
  app-text-field( v-if="isMask" :value="currentDelimeter" :label="$t('section.elementTree.separator')" @input='setNewCurrentDelimeter' )

  app-text-field( v-if="isSearchable" v-model="searching" :label="$t('section.elementTree.search')" test-id="element-tree-search" )

  v-virtual-scroll.__project_view_tree.base-scroll-body(
    v-if="loaded" 
    :items="conditionTreeCache.tree.items || []"
    item-height="24"
    height='300')
    template(v-slot:default="{ item }")
      div._tree-item.d-flex.pa-1( @click="$emit('selectAttr', item)") 
        .item-level(v-if='item.level' v-for='levels in item.level')
        v-icon.item__toggle(
          v-if='item.children' 
          :color='item.children.length > 0 ? "#757575" : "white"' 
          :disabled='!item.children.length > 0'
          :class='{ "item__toggle--close" : !conditionTreeCache.isOpened(item) }'
          tag="button"
        ) arrow_drop_down
        span.ml-1.corrects(:style="( form.manualOperand == item.name ) || ( form.operandUuid == item.uuid ) ? activeClass : ''" test-id="condition-tree-item" :title="itemName(item)") {{ itemName(item) }} 
  
  div( v-else )
    v-skeleton-loader( type="list-item-three-line" light )

</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
  name: "ConditionClassAttribute",

  props: {
    conditionTreeCache: null
  },

  computed:{
    ...mapState('condition', ['form', 'loaded', 'search', 'currentDelimeter']),
    ...mapState("copyutil", ["copiedElementAttribute"]),
    ...mapGetters('condition', ['activeClass', 'isSearchable', 'isMask','isGeometry','isIdProp','isGridProp']),


    searching:{
      get(){
        return this.search
      },
      set(value) {
        let v = value
        // if(value?.includes(':') || null) v = value.split(":")[0]
        this.setSearch(v)
      }
    }
  },

  watch:{
    search(v){
      if(!this.isGeometry && !this.isIdProp && !this.isGridProp){
        this.handleSearch(v || '')
      }
    }
  },  
  
  beforeUpdate () {
    if(this.copiedElementAttribute && this.copiedElementAttribute.elementType && this.copiedElementAttribute.elementType.headerName == "Атрибут")
    if ((this.search == this.copiedElementAttribute.name || this.search == null) && this.isSearchable){
      this.handleSearch(this.copiedElementAttribute.name)
      this.setSearch(this.copiedElementAttribute.name)
    }
  },

  methods: {
    ...mapMutations('condition', ['setSearch', 'setCurrentDelimeter'] ),

    itemName (item) {
      return (item.name && item.name!='') ? item.name : (item.title && item.title!='') ? item.title : (item.classTitle && item.classTitle!='') ? item.classTitle : this.$t('section.elementTree.name')
    },

    handleSearch(option) {
      this.conditionTreeCache.search(option)
      // const foundItem = this.conditionTreeCache.tree.items.find(item => item.name.includes(option))
      // if(foundItem) this.$emit('selectAttr', foundItem)
    },

    setNewCurrentDelimeter (value) {
      this.setCurrentDelimeter(value)
      this.$emit('input')
    }
  }

}
</script>

<style lang="scss" scoped>
._tree-item { 
  font: normal 12px/16px "Roboto", sans-serif;
  color: black;
  transition: .1s;
  white-space: nowrap;
  text-overflow: revert-layer;
  overflow: hidden;

  &:hover {
    background: #f0f0f0;
  }
}
.item__toggle {
  font-size: 16px;

  &--open {
    transform: none;
  }

  &--close {
    transform: rotate(-90deg);
  }
}
.item-level {
  width: 8px
}
.corrects {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<template lang="pug">
div( style= "height:300px" )
  app-text( v-for="item in condAxisEnum" :key="item.value" test-id="collision-condition-parameter" ) 
    div.param( v-if="item && item.name != 'MANUALINPUT'" :style="parametersWithTabs(item) ? activeClass : ''" @click="selectOperand(item)" ) {{ $t(`section.elementTree.condition.${item.name}`) }}
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
export default {
  name: "ConditionParameter",

  props: {
    condAxisEnum: null,
  },

  computed: {
    ...mapState('condition', ['form']),
    ...mapGetters('condition', ['activeClass']),
  },

  methods: {
    ...mapMutations('condition', ['setSearchClassificator', 'setHandleClassificator', 'setHandleClassification']),
    selectOperand(item){
      this.setHandleClassificator( null ) 
      this.setHandleClassification( null ) 
      this.setSearchClassificator( false )
      this.$emit('selectOperand', item)
    },

    parametersWithTabs(item) {
      const attributeNames = ['USERMANUALPROP', 'USERPROP', 'MASK', 'BASEATTR', 'MANUALINPUT', 'ATTRIBUTE'];
      
      if (attributeNames.includes(this.form.operand.name)) return attributeNames.includes(item.name)
      return this.form.operand.name === item.name
    },
  }

}
</script>

<style>
.param {
  cursor: pointer;
}
</style>
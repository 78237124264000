import i18n from "@/plugins/i18n"
export class ConditionTreeCache {
  constructor() {
    this.tree = {
      items: null,
      map: Map,
    },

    this.opened = new Set()
    this.excluded = new Set()

    this.beforeSearch = null
    this.searchItemsMap = null
    this.flag = true
    this.local = localStorage.getItem("locale")
  }

  setItems(items, declouse = false, opened = []) {
    this.tree.items = items.slice()
    let map_items = this.declouseTree(items)
    
    this.opened.clear()
    this.opened = new Set(opened)
    
    if(declouse) {
      this.tree.items = map_items
      this.opened = new Set(this.tree.items.map(item => item.uuid))
    }
    
    this.tree.map = new Map(map_items.map(
      (item, index) => [item.uuid, {item, index}]
    ))

    this.excluded.clear()
    this.beforeSearch = null
    this.searchItemsMap = null
  }

  open(item) {
    this.flag = true
    if(this.isOpened(item)) return

    let parent_index = this.tree.items.findIndex(tree_item => item.uuid === tree_item.uuid)

    this.opened.add(item.uuid)

    item.children?.map(child => {
      if(!this.searchItemsMap || this.searchItemsMap.has(child.uuid)) {
        parent_index ++
        this.tree.items.splice(parent_index, 0, child)
      }
      
      if(this.isOpened(child)) {
        this.opened.delete(child.uuid)

        let last_child_index = this.open(child)
        parent_index = last_child_index
      } 
    })

    return parent_index
  }

  close(item, closen_flag = true) {
  
    if (closen_flag) {
      this.opened.delete(item.uuid)
    }
  
    if (item.children) {
      for (let i = item.children.length - 1; i >= 0; i--) {
        const child = item.children[i]
        const childIndex = this.tree.items.indexOf(child)
        if (childIndex !== -1) {
          this.tree.items.splice(childIndex, 1)
          if (this.isOpened(child)) {
            this.close(child, childIndex, false)
          }
        }
      }
    }
  }


  declouseTree(items, level = 0, parent = null, child_property = "children") {    
    let decloused = []

    items.map(item => {
      item.level = level
      item.parent = parent

      decloused.push(item)
      
      if(item[child_property]) {
        decloused = decloused.concat(
          this.declouseTree(item[child_property], item.level + 1, item)
        )
      }
    })

    return decloused
  }

  search(option) {
    this.excluded.clear()

    if(option === "") {
      this.breakSearching()
      return
    }

    if(!this.beforeSearch) {
      this.beforeSearch = {
        items: this.tree.items,
        opened: new Set(this.opened)
      }
    }

    this.opened.clear()
    
    let supreme_parents = this.getSupremeParents()
    supreme_parents.map(parent => {
      this.filterItems(parent, option)
    })

    let desired = []

    this.tree.map.forEach(value => {
      if (!this.excluded.has(value.item.uuid)) {
        desired.push(value.item)
      }
    })

    this.tree.items = desired
    this.opened = new Set(this.tree.items.map(item => item.uuid))
    this.searchItemsMap = new Map(this.tree.items.map(
      (item, index) => [item.uuid, {item, index}]
    )) 
  }

  breakSearching() {
    if (this.beforeSearch) {
      this.opened = this.beforeSearch.opened
      this.tree.items = this.beforeSearch.items
    }
    this.searchItemsMap = null

    this.beforeSearch = null
  }

  filterItems(item, option) {
    if(!option) return false

    let match = false
    
    if(this.itemName(item).toLocaleLowerCase().includes(option.toLocaleLowerCase())) return true
    
    item.children?.map(child => {
      if(this.filterItems(child, option)) {
        match = true
      }
    })

    if(match) return true

    this.excluded.add(item.uuid)
  }

  itemName (item) {
    return (item.name && item.name!='') ? item.name : (item.title && item.title!='') ? item.title : (item.classTitle && item.classTitle!='') ? item.classTitle : i18n.t('section.elementTree.name', this.locale)
  }

  isOpened(item) {
    return this.opened.has(item.uuid)
  }

  getLevel(item) {
    return this.tree.map.get(item.uuid).item.level
  }

  getParent(item) {
    return this.tree.map.get(item.uuid).item.parent
  }

  getAllParents(item) {
    let parents = []
    let parent = this.getParent(item)

    if (parent) {
      parents.push(parent)
      if (parent.parent)  {
        parents = parents.concat(this.getAllParents(parent))
      }
    }
    return parents
  }

  getSupremeParents() {
    if (this.beforeSearch) {
      return this.beforeSearch.items.filter(item => item.level === 0)
    }
    return this.tree.items.filter(item => item.level === 0)
  }

  getById(id) {
    return this.tree.items.find(item => item.uuid === id)
  }
}
<template lang="pug">
  v-form( ref="valueForm" @submit.prevent="submit" )
    div( v-if="isAttr && form.operator && (form.operator.name!='EX' && form.operator.name!='NOTEX')" )
      app-select.select-maxw( v-if="isAttr && form.operandUuid != null && (currentType == 'ENUM' || currentType == 'BOOL')" v-model="form.value" :items="attrTypeEnumExists" :label="attrLabel()" item-value="value" )
      app-text-field( v-if="isAttr && currentType != 'ENUM' && currentType != 'BOOL'"  v-model="form.value" :label="attrLabel()" :rules="setRule" test-id="value-set-element")
  
      .text-right
        v-btn.mr-1(v-if="form.operandUuid == 'a1a6881f-4698-40dc-baae-54ad1c98c103' && form.operator.name == 'IN' && selectedElements.length > 0" @click.stop="insertSelectedElements" :color="copyColor" fab width="36" height="36" :title="$t('section.elementTree.copySelectedItems')")
          v-icon content_copy

    div( v-if="!isAttr && form.operator && (form.operator.name=='CONT' || form.operator.name=='NOTCONT')" ) 
      app-text-field( v-model="form.value" :label="$t('section.elementTree.value')" :rules="[this.rules.required]" )
    div( v-else ) 
      template(v-if='isDivSelectable && checkValue && condValuesLength')
        app-text-field(label='Поиск' v-model='conditionValueSearchText')
      v-virtual-scroll.__project_view_tree.base-scroll-body(
        v-if="isDivSelectable && checkValue && condValuesLength"
        :items="condValues.filter(item => itemName(item).toLowerCase().includes(conditionValueSearchText.toLowerCase()))"
        item-height="16"
        height='224')
        template(v-slot:default="{ item }")
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              app-text(v-bind='attrs' v-on='on').text-truncate
                div( v-if="!form.operandUuid" @click="$emit('selectOperandId', item.uuid, itemName(item))" test-id="condition-values") {{ itemName(item) }}
                div( v-else-if="form.operandUuid" :style="( form.operandUuid == item.uuid ) || ( form.manualOperand == item.title ) ? activeClass : ''" @click="$emit('selectOperandId', item.uuid, itemName(item))" test-id="condition-values") {{ itemName(item) }}
            div( v-if="!form.operandUuid" @click="$emit('selectOperandId', item.uuid, itemName(item))" test-id="condition-values") {{ itemName(item) }}
            div( v-else-if="form.operandUuid" :style="( form.operandUuid == item.uuid ) || ( form.manualOperand == item.title ) ? activeClass : ''" @click="$emit('selectOperandId', item.uuid, itemName(item))" test-id="condition-values") {{ itemName(item) }}
            
      v-virtual-scroll.__project_view_tree.base-scroll-body(
        v-if="isGroup && checkValue" 
        :items="condValues"
        item-height="16"
        height='286')
        template(v-slot:default="{ item }")
          app-text
            div( :style="( form.operandUuid == item.logicGroup.uuid ) || ( form.operandUuid == item.uuid ) ? activeClass : ''" @click="$emit('selectOperandId', item.logicGroup.uuid, itemName(item))" test-id="condition-values" ) {{ itemName(item) }}

      .d-flex( v-if="form.operand.name == 'CLASS' && loaded" )
        app-text-field( :value="search" :label="$t('section.elementTree.search')" @input="handleSearch" clearable test-id="value-search-element" )
        .ifc-types
          app-text() 
            div.ifc-type-option( :style="searchThisModelIfcTypes ? activeClass : ''" @click="selectSearchThisModelIfcTypes()" ) {{ "IFC типы текущей модели" }}
          app-text() 
            div.ifc-type-option( :style="!searchThisModelIfcTypes ? activeClass : ''" @click="selectSearchAllIfcTypes()" ) {{ "Все IFC типы" }}
      
      v-virtual-scroll.__project_view_tree.base-scroll-body(
        v-if="form.operand.name == 'CLASS' && loaded"
        :items="ifcElementsTree"
        item-height="24"
        height='200'
        test-id="collision-comparison-value"
      )
        template(v-slot:default="{ item }")
          .tree-item.pa-1(@click="handleTreeOpen(item), $emit('selectOperandId', item.uuid, pathStartTitle(item.uuid))")
            .item-level(v-for='level in item.level')
            v-icon.item__toggle(
              :color='item.children.length > 0 ? "#757575" : "white"' 
              :disabled='!item.children.length > 0'
              :class='{ "item__toggle--close" : !items.isOpened(item) }'
              tag="button"
            ) arrow_drop_down
            strong.ml-1( v-if="item.type.name == 'CLASSIFICATOR'" ) {{ itemName(item) }} 
            span.ml-1( v-else :style="(form.operandUuid == item.uuid) || (form.manualOperand == item.name) ? activeClass : ''" ) {{ itemName(item) }}

        //- v-virtual-scroll.__project_view_tree.base-scroll-body( 
        //-   v-else
        //-   :items="allIfcTypes"
        //-   item-height="16"
        //-   height='286'
        //- )
        //-   template( v-slot:default="{ item }" )
        //-     app-text
        //-       div( v-if="!form.operandUuid"  @click="$emit('selectOperandId', item.uuid, itemName(item))" test-id="condition-values") {{ itemName(item) }}
        //-       div( v-else-if="form.operandUuid" :style="( form.operandUuid == item.uuid ) || ( form.manualOperand == item.uuid ) || ( form.manualOperand == item.title ) ? activeClass : ''" @click="$emit('selectOperandId', item.uuid, itemName(item))" test-id="condition-values") {{ itemName(item) }}

      v-progress-linear( v-if="isValueLoading && !loaded" indeterminate)

      app-text(v-if="loaded && !checkValue" align="center") {{ $t('section.elementTree.noValues') }}
      
      v-progress-linear( v-if="tree.loading" indeterminate )

      template( v-if="isClassificator && !tree.loading" )
        app-text-field( v-if="currentClassificator && !tree.loading" :value="nodeSearch" :label="$t('section.classification.menuClassification.codeSearch')" clearable @input="$emit('input', $event)" test-id="value-code-search")
            
        template( v-if="!form.operandUuid && searchClassificator && !currentClassificator" )
          condition-handle-classificator(
            :conditionTreeCache="items"
          )
        
        template( v-else-if="!searchMode" )
          v-virtual-scroll.__project_view_tree.base-scroll-body(
            v-if="ifcElementsTreeLength"
            :items="ifcElementsTree"
            item-height="24"
            height='200')
            template(v-slot:default="{ item }")
              .tree-item.pa-1(@click="handleTreeOpen(item), $emit('selectClassificatorNode', item)")
                .item-level(v-for='level in item.level')
                v-icon.item__toggle(
                  :color='item.hasChilds ? "#757575" : "white"' 
                  :disabled='!item.hasChilds'
                  :class='{ "item__toggle--close" : !items.isOpened(item) }'
                  tag="button"
                ) arrow_drop_down
                span.ml-1( :style="form.operandUuid == item.uuid ? activeClass : ''" test-id="value-classifier") {{ classificatorName(item) }}

        template( v-else )
          .base-scroll-body.condition-value__section-height
            app-text.ma-2( v-for="item in findedNode" :key="item.uuid" )
              div( :style="form.operandUuid == item.uuid ? activeClass : ''" @click="$emit('selectOperandId', item.uuid, classificatorName(item))" test-id="class-search-mode-value") {{ classificatorName(item) }}
              //- пересмотреть логику выделения, вместо uuid использовать manualOperand, в который сохранять весь путь целиком
</template>

<script>
import { $_validate_rules } from '@/common/ValidateRules'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import AttributeCheckingValues from "@/assets/model/collision/AttributeCheckingValues"
import { api } from "@/api"
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator"
import ConditionHandleClassificator from './ConditionHandleClassificator.vue'
import { ConditionTreeCache } from '@/assets/model/smeta/ConditionTreeCache';

const findById = (array, id, tail = []) => {
  for (const obj of array) {
    if (obj.uuid === id) {
      tail.push(obj);
      return tail;
    }
    if (obj.children) {
      const childrenTail = findById(obj.children, id, [...tail].concat(obj));
      if (childrenTail.length) return childrenTail;
    }
  }
  return [];
}

export default {
  name: "ConditionValue",

  mixins: [$_validate_rules],

  inject: ['valueList'],

  components: {
    ConditionHandleClassificator
  },

  data() {
    return {
      conditionValueSearchText: '',
      filteredConditionTreeCache: new ConditionTreeCache(),
      items: null,
    }
  },

  props:{
    attrTypeEnum: null,
    condValues: null,
    findedNode: null,
    conditionTreeCache: null,

    isCollisionRule: null,

    titleFirst: Boolean,

    // hideNoDataMessage: Boolean,
  },

  mounted() {
    this.items = this.conditionTreeCache
  },

  watch: {
    loaded() {
      this.items = this.conditionTreeCache
      this.filteredConditionTreeCache.setItems(this.filterByExistanceInTheModel(this.conditionTreeCache?.tree?.items || []), true)
      if (this.searchThisModelIfcTypes) this.selectSearchThisModelIfcTypes()
    }
  },

  computed:{
    ...mapState('condition', ['form', 'loaded', 'search', 'currentType', 'currentClassificator', 'tree', 'nodeSearch', 'searchMode', 'searchClassificator', 'searchThisModelIfcTypes', 'allIfcTypes']),
    ...mapGetters('condition', ['activeClass', 'isDivSelectable', 'isClassificator', 'isAttr', 'isGroup']),
    ...mapGetters('rule/details', ['rule']),
    ...mapGetters('project', ['projectUuid']),

    attrTypeEnumExists() {
      return this.attrTypeEnum || ['']
    },

    condValuesLength() {
      return this.condValues.length
    },

    selectedElements() {
      return XeokitMediator.ElementsSelection.selectedElements
    },

    setRule() {
      if ( this.isAttr ){
        if ( !this.currentType || this.currentType == "STRING" ) return [this.rules.required]
        else return [this.rules.required, this.rules.double]
      }
      return []
    },

    attributeCheckingValues() {
      return Object.keys(AttributeCheckingValues)
    },

    copyColor() {
      return this.$vuetify.theme.themes.dark.primary.lighten3;
    },

    isValueLoading() {
      if (!this.form.operand.name)
        return false
      if (this.form.operand.name === 'CLASSIFICATOR')
        return false
      if (this.form.operand.name === 'IDPROP')
        return false
      if (this.form.operand.name === 'ATTRIBUTE')
        return false
      if (this.form.operand.name === 'USERPROP')
        return false
      if (this.form.operand.name === 'MASK')
        return false
      if (this.form.operand.name === 'GEOMETRY')
        return false
      return true
    },

    checkValue() {
      return this.items?.tree?.items?.length > 0
    },

    ifcElementsTree() {
      return this.items?.tree?.items || []
    },

    ifcElementsTreeLength() {
      return this.items?.tree?.items?.length || null
    }
  },

  methods:{
    ...mapMutations('condition', ['setSearch']),
    ...mapActions('condition', ['loadChildrens', 'setSearchThisModelIfcTypes']),

    submit(){
      if(this.form.operator?.name == 'IN') {
        if(this.form.operandUuid == 'a1a6881f-4698-40dc-baae-54ad1c98c102')
          this.form.elements = this.form.value.split(',')
        else
          this.form.stringArray = this.form.value.split(',')
      }
      return this.$refs.valueForm.validate()
    },

    validate(){
      if (this.isCollisionRule) {
        return this.form.value !== null && this.form.value.trim() !== ""
      }
      return this.submit()
    },

    itemName (item) {
      if (this.titleFirst) {
        if (item?.title && item?.title!='') {
          if (item.title.includes('notify.')) return this.$t(item.title)
          return item.title.includes('model.name') ? this.$t('notify.' + item.title.replace("{", "").replace("}","")) : item.title
        }
        else if (item?.name && item?.name != '') {
          if (item.name.includes('notify.')) return this.$t(item.name)
          return item.name.includes('model.name') ? this.$t('notify.' + item.name.replace("{", "").replace("}","")) : item.name
        }
        else return (item.classTitle && item.classTitle!='') ? item.classTitle : this.$t('section.elementTree.name')
      
      }
      else {
        if (item?.name && item?.name != '') {
          if (item.name.includes('notify.')) return this.$t(item.name)
          return item.name.includes('model.name') ? this.$t('notify.' + item.name.replace("{", "").replace("}","")) : item.name
        }
        else if (item?.title && item?.title!='') {
          if (item.title.includes('notify.')) return this.$t(item.title)
          return item.title.includes('model.name') ? this.$t('notify.' + item.title.replace("{", "").replace("}","")) : item.title
        }
        else return (item.classTitle && item.classTitle!='') ? item.classTitle : this.$t('section.elementTree.name')
      }
    },

    classificatorName (item) {
      return item.pressmark ? item.pressmark + " - " + item.title : item.title
    },
    
    attrLabel () {
      return this.$t('section.elementTree.enterValue')
      // return this.attrUnit!=null && this.attrUnit!=''? this.attrUnit : this.$t('section.elementTree.enterValue')
    },

    handleSearch(option) {
      this.setSearch(option)
      this.items.search(option)
    },

    pathStartTitle(id) {
      let valueList = this.items.tree.items
      let path = findById(valueList, id)
      if(path.length>1) return path[0].name+"/"+path[path.length-1].name
      if(path.length==1) return path[0].name
      else return ""
    },

    handleTreeOpen(item) {
      if(item.children?.length === 0 && item.hasChilds) {
        this.loadChildrens(item).then(children => {
          item.children = children.map(child => {
            child.level = item.level + 1
            return child
          })
          this.items.open(item)
        })
      }
      else {
        if(this.items.isOpened(item)) {
          this.items.close(item)
        }
        else {
          this.items.open(item)
        }
      }
    },

    selectSearchThisModelIfcTypes() {
      this.setSearchThisModelIfcTypes(true)
      this.items = this.conditionTreeCache
    },

    selectSearchAllIfcTypes() {
      this.setSearchThisModelIfcTypes(false)

      this.items = this.filteredConditionTreeCache
    },

    handleSelectAttributeCheckingValue(value) {
      const selectedValue = AttributeCheckingValues[value]
      this.form.value = selectedValue
    }, 

    insertSelectedElements() {
      api.projects.globalIdToIcfGlobalId(this.selectedElements, this.projectUuid).then(data => {
        this.form.value = data.join()
      })
    },

    filterByExistanceInTheModel(items = []) {
      let list = []
      let tempParent = null

      for (let i = items.length - 1; i >= 0; i--) {
        if (items[i].be || (tempParent?.uuid == items[i].uuid)) {
          list.push(items[i])
          tempParent = items[i].parent
        }
      }
      list.reverse()

      return list
    },
  }
}
</script>
<style lang='scss'>
  .item-level {
    min-width: 8px;
  }
  .tree-item {
    font: normal 12px/16px "Roboto", sans-serif;
    color: black;
    transition: .1s;
    white-space: nowrap;

    display: flex;
    align-items: center;

    &:hover {
      background: #f0f0f0;
    }
  }
  
  .item__toggle {
    font-size: 16px !important;

    &--open {
      transform: none;
    }

    &--close {
      transform: rotate(-90deg);
    }
  }

  .attribute-checking-value {
    font: normal 14px/20px "Roboto", sans-serif;
    color: black;
  }

  ._value-selected {
    color: #3b93af;
  }

  .condition-value__section-height {
    max-height: 230px;
  }

  .ifc-types {
    margin-left: 8px;
    margin-top: 4px;
  }

  .ifc-type-option {
    cursor: pointer;
  }
</style>
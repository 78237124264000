<template lang="pug">
  app-dialog(v-model="$_show" :header="title" @confirm="fillForm()" :hideOverlay="hideOverlay" :confirmText="$t('button.save')" :cancelText="cancelText" width="840")
    app-section
      .form-title.pt-1
        .black--text.title-form-font(test-id="form-title") {{ formTitle }}
        div.separator.d-block
      .task-forms-details.task-form-details-fonts
        div(v-for="(f, index) in fields")
          field.mt-1( :field="f" :key="f.type + ':' + f.uuid" :valueList="taskTypeFields" test-id="form-field" )
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { api } from "@/api"
import Field from "./Field"
import DialogToggable from '@/components/app/DialogToggable'

export default {
  name: "FillForm",

  props: {
    title: {
      default: '',
      type:String
    },
    cancelText: {
      default: '',
      type:String
    },
    formId: null,
    selectedTask: null,
    returnData: {
      default:false,
      type:Boolean
    },
    hideOverlay: {
      default:false,
      type:Boolean
    }
  },

  mixins: [DialogToggable],
  components: {
    Field
  },

  data () {
    const defaultForm = Object.freeze({
          uuid: null,
          bind: null,
          fields: [],
          form: { "uuid" : this.formId }
        })

    return {
      taskTypeFields: new Map(),
      taskFields: [],
      dialog: {
        showForm: true,
      },
      formData: Object.assign({}, defaultForm),
      defaultForm,
      type: null,
      fieldValues: [],
      edit: false,
    }
  },

  mounted () {
    this.$store.dispatch('project/loadAxisEnum')
    this.taskTypeFields = new Map()
  },

  watch: {
    $_show (value) {
      if (value) {
        this.taskTypeFields = new Map()
        this.formData.fields = []
        this.formData = Object.assign({}, this.defaultForm)
        if(this.selectedTask && this.selectedTask.uuid != null) {
          if(this.selectedTask.taskType.fields){
            this.selectedTask.taskType.fields.map((el, index) => { this.taskFields.push(el.field); this.taskFields[index].defaultValue = el.value })
          }
        }
       
        // this.formData.bind = UUID того, к чему привязываем данные заполняемой формы
        this.$store.dispatch('forms/LOAD_FORM', { formId: this.formId })
      } else {
        this.taskFields = []
        this.taskTypeFields = new Map()
      }
    },
  },

  computed: {
    ...mapGetters('forms', ['form', 'isFormLoaded']),
    ...mapGetters('task', ['getTaskType']),

    formTitle() {
      return this.isFormLoaded ? this.form.name : this.$t('form.form')
    },

    fields() {
      if(this.selectedTask){
        return this.taskFields
      } else {
        return this.isFormLoaded ? this.form.fields : []
      }
    },

  },

  methods: {
    ...mapMutations('task', ['setSelectedTaskField', 'setSelectedTaskType']),
    ...mapActions('task', ['loadTasks', 'setTaskType']),

    fillForm() {
      let formObj = {}
      if(this.selectedTask){
        formObj = this.selectedTask.taskType
        this.formData.uuid = formObj.uuid
        this.formData.bind = formObj.bind
        this.formData.form = this.form
        this.formData.fields = []
      }

      this.taskTypeFields.forEach((values) => {
        this.formData.fields.push(values)
      })

      if (this.returnData){ 
        this.$emit("filled", this.formData.fields)
      } else {
        api.forms.fill(this.formData).then(data => {
          if(this.selectedTask){
            this.$store.commit('task/setSelectedTaskType', {taskType: data})
          }
        })
      }
    },
  }
}
</script>

<style scoped>
  .separator {
    height: 1px;
    background-color: #E5E5E5;
  }

  .title-form-font {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 800;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 17px !important;
  }

  .task-forms-details{
    max-height: 600px;
    overflow-x: hidden;
  }

  .task-form-details-fonts{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    color: #4d4d4d;
    text-align: left;
  }
</style>
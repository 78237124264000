<template lang="pug">
  app-dialog( v-model="$_show" :width="simpleView ? 600 : 900" complex :header="$t('section.elementTree.selection')" ) 

    v-row( no-gutters )
      v-col( :cols="simpleView ? 4 : 2" )
        //- Выбор параметра
        app-panel-section( :title="$t('section.elementTree.selectingParam')" :headerEllipsis="true")
          condition-parameter( 
            :condAxisEnum="condAxisEnum"
            @selectOperand="selectOperand" 
          )

      v-col(v-if='isAttributeTabs' :cols="isGridProp? 2 : simpleView ? 8 : 5" style="padding-left: 1px;")
        app-panel-section(:title="$t('section.elementTree.selectingClassAttr')")
          div.d-flex.align-center
            v-text-field(v-model='searching' height='24' dense :label="$t('section.elementTree.search')" outlined light clearable hide-details @clear='searching = ""')
            
            v-menu(v-model='maskMenuActive' :close-on-content-click='false')
              template(v-slot:activator='{ on, attrs }')
                v-icon.ml-2(v-on='on' v-bind='attrs' color='surface' @click='maskMenuDelimeter = currentDelimeter') mdi-layers-outline
              
              app-section
                //- app-checkbox.ma-0.pa-0.mb-2(light) группировать до первого разделителя
                app-text-field.mb-2(v-model='maskMenuDelimeter' label='например: _' hide-details clearable)
                
                div.d-flex
                  app-button.ml-auto.mr-2(color='surface lighten-1' small @click='maskMenuDelimeter = currentDelimeter, maskMenuActive = false') отмена
                  app-button(color='accent' small @click='setCurrentDelimeter(maskMenuDelimeter), setMaskAsFormOperand(), maskMenuActive = false, maskChange()') ок
          
          div.d-flex.justify-space-between
            v-btn.flex.rounded-0.pa-1.text-none(v-for='tab in attributeTabs' :color='activeAttributeTab === tab ? "accent" : "primary darken2"' :class='activeAttributeTab === tab && "tab-active"' text small dense @click='activeAttributeTab = tab') {{ attributeTabsName[tab] }}

          //- v-tabs.mb-2(v-model='activeAttributeTab' light show-arrows)
            v-tab(href='#BASEATTR') Базовые
            v-tab(href='#USERPROP') Наборы
            v-tab(href='#USERMANUALPROP') Универсальные
            v-tab(href='#MANUALINPUT') Вручную
            v-tab(href='#MASK' style='display: none;') {{ $t("section.elementTree.condition.MASK") }}
            v-tab(href='#ATTRIBUTE' style='display: none;') {{ $t("section.elementTree.condition.ATTRIBUTE") }}
          
          template(v-if='activeAttributeTab === "MANUALINPUT"')
            v-combobox(v-model='MANUALINPUT_prefix' :items='(conditionTreeCache.tree.items || []).map(item => itemName(item))' label='Имя набора' outlined light dense @input='form.manualOperand = MANUALINPUT_prefix + "/" + MANUALINPUT_postfix, currentOperandTitle = form.manualOperand' :menu-props="{ contentClass: 'three-dropdown base-scroll-body' }")
              template(v-slot:item="{ item, index }")
                v-tooltip(bottom open-delay="300")  
                  template(v-slot:activator="{ on }")
                    v-list-item(:key="index" v-on="on" style='padding-left: 0px;')
                      v-list-item-title(style='max-width: 320px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;') {{ item }}  
                  span {{ item }}   

            div(v-if="hoveredItem" :style="tooltipStyle" class="tooltip") {{ hoveredItem }}

            app-text-field(v-model='MANUALINPUT_postfix' label='Имя атрибута' @input='form.manualOperand = MANUALINPUT_prefix + "/" + MANUALINPUT_postfix, currentOperandTitle = form.manualOperand')

          v-virtual-scroll.base-scroll-body(v-else :items='conditionTreeCache.tree.items || []' item-height='24' height='228' ref='attributes-tree')
            template(v-slot:default='{ item }')
              div._tree-item.d-flex.pa-1(@click='selectAttr(item)')
                div.ml-2(v-for='level in (item.level || 0)')
                v-icon.item__toggle(v-if='item.children && item.children.length' :class='{ "item__toggle--close" : !conditionTreeCache.isOpened(item) }' color='primary lighten-2') arrow_drop_down
                div.px-2(v-else)
                span.ml-1.text-truncate(:style="( form.manualOperand == item.name ) || ( form.operandUuid == item.uuid ) ? activeClass : ''" test-id="condition-tree-item" :title="itemName(item)") {{ itemName(item) }}

      v-col( v-else-if="isAttr" :cols="isGridProp? 2 : simpleView ? 8 : 5" style="padding-left: 1px;" )
        //- Выбор класса/атрибута
        app-panel-section( :title="isGridProp? $t('section.elementTree.gridprop.type') : $t('section.elementTree.selectingClassAttr')" )
          condition-class-attribute(
            :conditionTreeCache="conditionTreeCache"
            @input="maskChange"
            @selectAttr="selectAttr"
            @handleTreeOpen="handleTreeOpen"
          )

      v-col( v-if="isConst && simpleView" :cols="8" style="padding-left: 1px;" )
        app-panel-section( :title="$t('section.elementTree.inputConstant')" )
          v-form( ref="valueForm" @submit.prevent="submit" )
            div
              app-text-field( v-model="form.value" :label="$t('section.elementTree.value')" @input="checkInput" :rules="constTypeRules" )

      v-col( v-if="simpleView && !isConst && !isAttr && form.operand.name !== 'MATERIALS' && form.operand.name !== 'SYSTEMS'" style="padding-left: 1px;" )
        app-panel-section( :title="$t('section.elementTree.selectingValue')" :headerEllipsis="true" )
          app-text( align="center" ) {{ $t('section.elementTree.noValues') }}

      v-col( v-if="!simpleView && !isCollisionRule && !isGridProp" cols="2" style="padding-left: 1px;" )
        //- Выбор Классификаторa
        app-panel-section( v-if="isClassificator" :title="$t('section.elementTree.choosingClassificator')" )
          app-text
            div.pb-1( @click="handleInput()" :style="!currentClassificator ? activeClass : ''" ) {{ $t('section.collision.rulesFounder.manualInput') }} 
          hr( class="solid" style="border-top: 1px; border-color: #666666;" )
          condition-classificator.pt-1(
            :conditionTreeCache="conditionTreeCache"
          )
          
        //- Выбор сравнения
        app-panel-section( v-else :title="$t('section.elementTree.choosingComparison')" :headerEllipsis="true")
          condition-comparison(:operatorFilter="operatorFilter")

      v-col( v-if="((!simpleView || axisFilter.indexOf( 'CLASS' )!=-1) && !isGridProp) || form.operand.name === 'MATERIALS' || form.operand.name === 'SYSTEMS'" style="padding-left: 1px;" )
        //- Выбор значения
        app-panel-section( :title="$t('section.elementTree.selectingValue')")
          condition-value(
            v-if='$_show'
            ref="valueForm"
            :attrTypeEnum="attrTypeEnum" 
            :condValues="condValues"
            :findedNode="findedNode"
            :conditionTreeCache="conditionTreeCache"
            :isCollisionRule="isCollisionRule"
            :titleFirst = "form.operand.name === 'IFC'"
            @selectOperandId="selectOperandId"
            @input="nodeSearchChange"
            @selectClassificatorNode="selectClassificatorNode"
          )

      v-col( v-if="isGridProp" style="padding-left: 1px;" )
        //- Выбор значения
        app-panel-section( :title="$t('section.elementTree.selectingValue')" )
          condition-grid-value(
            ref="valueForm"
            :condValues="condValues"
            :isCollisionRule="isCollisionRule"
          )

    v-row.justify-end.white( no-gutters style="margin-top: 1px;" )
      app-button.mt-3.mb-2.mr-2( @click="createCondition" :disabled="!valid || isButtonDisabled" color="accent" test-id="collision-condition-add" light) OK

</template>

<script>
/* eslint-disable */
import DialogToggable from '@/components/app/DialogToggable'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { $_validate_rules } from '@/common/ValidateRules'
import { projectService } from '@/_services'
import { api } from "@/api"
import { ConditionTreeCache } from '@/assets/model/smeta/ConditionTreeCache'

import ConditionParameter from './conditionSections/ConditionParameter.vue'
import ConditionClassAttribute from './conditionSections/ConditionClassAttribute.vue'
import ConditionClassificator from './conditionSections/ConditionClassificator.vue'
import ConditionComparison from './conditionSections/ConditionComparison.vue'
import ConditionValue from './conditionSections/ConditionValue.vue'
import ConditionGridValue from './conditionSections/ConditionGridValue.vue'
import { useSmetaPanelStore } from '@/pinia/smeta/smetaPanel.store'
import { conditionCache } from '@/assets/model/smeta/ConditionCache'

const smetaPanelStore = useSmetaPanelStore()

const findById = (array, id, tail = []) => {
  for (const obj of array) {
    if (obj.uuid === id) {
      tail.push(obj);
      return tail;
    }
    if (obj.children) {
      const childrenTail = findById(obj.children, id, [...tail].concat(obj));
      if (childrenTail.length) return childrenTail;
    }
  }
  return [];
}

const simpleOperator = {name:'EX',value:8,title: 'Имеет значение'}

export default {
  name: 'Condition',

  mixins: [DialogToggable, $_validate_rules],

  components: {
    ConditionParameter,
    ConditionClassAttribute,
    ConditionClassificator,
    ConditionComparison,
    ConditionValue,
    ConditionGridValue,
  },

  provide: function () {
    return {
      valueList: this.valueList
    }
  },

  props: {
    groupUuid: null,
    condition: null,
    axisFilter: Array,
    operatorFilter: Array,
    simpleView: {
      default: false,
      type: Boolean
    },
    calcType: {
      type: String,
      default: ''
    },

    isCollisionRule: Boolean,

    returnTitleName: {
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      MANUALINPUT_prefix: '',
      MANUALINPUT_postfix: '',

      attrTypeEnum: null,
      attrUnit: null,
      currentOperandTitle: null,
      valueListIteration: 1,
      // valueList: null,
      maskTimeout: null,
      opens: [],
      nodeSearchTimeout: null,
      findedNode: [],
      pickedClassificator: null,
      valueSearchClassificator: null,
      classificatorTitles: [],
      recursionFlag: null,
      parsedPressmark: null,

      conditionTreeCache: new ConditionTreeCache(),
      isButtonDisabled: false,

      maskMenuActive: false,
      maskMenuDelimeter: null, // "",
      lastparameter: null,
      hoveredItem: null,
      tooltipStyle: {},
      hoverTimeout: null,
    }
  },
  watch: {
    nodeSearch() {
      if (this.nodeSearch && this.nodeSearch.trim() != "") {
        this.setSearchMode( true )
      } else {
        this.setSearchMode( false )
      }
    },
    async $_show (value) {
      if (value) {
        this.setCancelCondition(true)
        let baseObj = this.condition || this.defaultCondition
        this.setForm(JSON.parse(JSON.stringify(baseObj)))
        if(this.condition?.gridCondition) this.setGridForm(JSON.parse(JSON.stringify(this.condition.gridCondition)))
        await this.updateForm()
        // if (this.isDivSelectable) this.search = baseObj.title?.split("/")[1]
        if (this.isAttributeTabs) {
          this.conditionTreeCache.search(this.search)
        }
      } 
      else {
        this.setCancelCondition(false)
      }
    },
  },

  computed: {
    ...mapGetters('project', ['axisEnum', 'isAxisEnumLoaded', 'conditionOperator', 'isConditionOperatorLoaded', 'logicOperator', 'isLogicOperatorLoaded', 'projectUuid', 'hashProject', 'flatlist']),
    ...mapState('project', ['selectedModel', 'projectSettings']),
    ...mapState('copyutil', ['copiedElementAttribute']),
    ...mapState('smeta', ['projectClassificatorUuid','projectClassificators','nodeTree','openedNodes']),
    ...mapState('condition', ['form', 'defaultCondition', 'loaded', 'search', 'currentType', 'currentClassificator', 'tree', 'currentDelimeter', 'gridForm', 
                              'searchClassificator', 'handleClassificator', 'handleClassification', 'copyValue', 'copyManualOperand', 'reducedСonditions']),
    ...mapState('condition', ['nodeSearch', 'searchMode']),
    ...mapGetters('condition', ['condOperator', 'isSearchable', 'isAttr', 'isConst', 'isGeometry', 'isClassificator', 'isIdProp' , 'isGridProp', 'activeClass']),
    ...mapState('projectDict', ['openUniversalAttributesPanel']),

    showGridCondition() {
      return window.settings.showGridCondition ?? false
    },

    checkValue() {
      return this.conditionTreeCache?.tree?.items?.length > 0
    },

    valid() {
      if (this.form.operand?.name === 'MANUALINPUT') {
        return this.MANUALINPUT_postfix && this.MANUALINPUT_prefix
      }
      if( this.isGridProp && this.form.operandUuid!=null &&
          (this.$refs.valueForm && this.$refs.valueForm.validate())) 
        return true
      if( this.simpleView && this.loaded && ((this.isAttr && this.form.operator!=null && (this.form.operandUuid!=null || (this.activeAttributeTab === "BASEATTR" && this.form.manualOperand))) 
          || (this.isConst && this.form.value!=null && this.form.value.trim()!='')) ) 
        return true
      if( this.loaded && this.isAttr && this.form.operator!=null && (this.form.operandUuid!=null || (this.activeAttributeTab === "BASEATTR" && this.form.manualOperand)) && 
          ((this.form.value!=null && this.form.value.trim()!='') || (this.form.operator.name=='EX' || this.form.operator.name=='NOTEX') ) && 
          (this.$refs.valueForm && this.$refs.valueForm.validate())
        ) 
        return true
      if( !this.isAttr && this.form.operand.name!='' && this.form.operator!=null && 
          ( (this.form.operandUuid!=null || this.form.manualOperand) || 
            ((this.form.operator.name=='CONT' || this.form.operator.name=='NOTCONT') && this.form.value!=null && this.form.value.trim()!='')
          ) 
        ) 
        return true
      if (this.isCollisionRule && this.form.operand.name === "ATTRIBUTE" || this.form.operand.name === "USERPROP" && this.form.value !== null && this.form.value.trim() !== "")
        return true
      if( this.handleClassificator?.trim() && this.handleClassification?.trim() && this.searchClassificator  && this.isClassificator ) return true
      if(this.form.operand?.name === 'MATERIALS' && this.form.manualOperand) return true
      
      return false
    },

    condAxisEnum() {
      let axis = this.showGridCondition ? this.axisEnum : this.axisEnum.filter(a => (a.name!=='GRIDPROP'))
      if (this.axisFilter && this.axisFilter.length > 0) return axis.filter(a => this.axisFilter.includes(a.name) && !['USERMANUALPROP', 'USERPROP', 'MASK', 'BASEATTR'].includes(a.name))
      return axis.filter(a => (a.cond==1 && a.name!=='CONST' && !['USERMANUALPROP', 'USERPROP', 'MASK', 'BASEATTR'].includes(a.name)))
    },

    condValues () {
      return this.valueListIteration && this.valueList || []
    },

    setRule() {
      if(this.isAttr){
           if(this.currentType == "STRING") return [this.rules.required]
           else return [this.rules.required, this.rules.double]
      }
      return []
    },

    constTypeRules(){
      if(!this.openUniversalAttributesPanel){
        if (this.calcType == 'TITLECALC') return [this.rules.required]
        return [this.rules.required, this.rules.double]
      }
      return [this.rules.required]
    },

    activeAttributeTab: {
      get() {
        return this.form.operand.name
      },
      async set(val) {
        await this.selectOperand(this.axisEnum.find(axis => axis.name === val))
        this.conditionTreeCache.search(this.search)
      }
    },

    searching: {
      get() {
        return this.search
      },
      set(val) {
        this.setSearch(val)
        this.conditionTreeCache.search(this.search)
      }
    },

    isAttributeTabs() {
      return this.form.operand && ["BASEATTR", "ATTRIBUTE", "USERMANUALPROP", "USERPROP", "MASK", "MANUALINPUT"].includes(this.form.operand.name)
    },

    attributeTabs() {
      return ["BASEATTR", "USERPROP", "USERMANUALPROP", "MANUALINPUT"]
    },

    attributeTabsName() {
      return {
        'BASEATTR': "Базовые",
        'USERPROP': "Наборы",
        'USERMANUALPROP': "Универсальные",
        'MANUALINPUT': "Вручную",
      }
    },
  },

  methods: {
    ...mapMutations('copyutil', ['setCopiedElementAttribute']),
    ...mapMutations('smeta', ['setProjectClassificators']),
    ...mapMutations('condition', ['setForm', 'setGridForm', 'setLoaded', 'setSearch', 'setCurrentType', 'setCurrentClassificator', 'setNodeSearch', 
                    'setSearchClassificator', 'setSearchMode', 'cleanGridForm', 'setManualOperand', 'setValue', 'setHandleClassificator', 'setHandleClassification', 'setCurrentDelimeter']),
    ...mapActions('condition', ['selectClassificator', 'LOAD_GRID', 'selectOperator']),
    ...mapMutations('projectDict', ['setCancelCondition']),

    recursionPickedElement( objArray, copy = false ) {
      let arr = this.form.manualOperand.split('/')
      let titleString = "'" + this.form.manualOperand + "' "
      let titleArr = this.reducedСonditions ? titleString.split('/') : this.form.manualOperand.split('/')
      for( let i = 0; i < objArray.length; i++ ) {
        if( ( objArray[i].name.trim() == arr[ arr.length - 1 ].trim() || objArray[i].name.trim() == titleArr[ titleArr.length - 1 ].trim() ) && !objArray[i]?.children?.length ) {
          this.selectAttr( objArray[i], true )
          this.recursionFlag = true
        }
        if( objArray[i]?.children?.length && !this.recursionFlag && !copy && ( arr.indexOf(objArray[i].name) !== -1 ) ) {
          this.importTreeOpen( objArray[i])
          this.recursionPickedElement( objArray[i].children )
        }

        if( copy ) {
          for( let j = 0; j < arr.length; j++ ) {
            if( objArray[i].children.length && !this.recursionFlag && objArray[i].name == arr[j] ) {
              this.importTreeOpen( objArray[i] )
              this.recursionPickedElement( objArray[i].children, true )
            }
          }
        }
      }
    },

    checkInput() {
      if(!this.openUniversalAttributesPanel){
        const regex = /[a-zа-я]/i;
        this.isButtonDisabled = regex.test(this.form.value)
      }
    },

    handleInput() {
      this.setNodeSearch(null)
      this.setSearchClassificator(true)
      this.form.operandUuid = null
      this.form.manualOperand = null
      this.setCurrentClassificator(null)
    },

    onMouseEnter(item) {
      this.hoverTimeout = setTimeout(() => {
        this.hoveredItem = item;
        this.updateTooltipPosition();
      }, 300);
    },
    onMouseLeave() {
      clearTimeout(this.hoverTimeout);
      this.hoveredItem = null;
    },
    updateTooltipPosition() {
      this.$nextTick(() => {
        const { x, y } = this.$event; 
        this.tooltipStyle = {
          top: `${y + 10}px`,
          left: `${x + 10}px`,
        };
      });
    },

    listProjectClassificators() {
      api.smeta.projClassListForChoice(this.projectUuid).then((data) => {
        this.pickedClassificator = data
        this.setProjectClassificators(data)

        this.openSelectedClassificator()
        for( let i = 0; i < data.length; i++ ) {
          this.classificatorTitles.push( data[i].title )
        }

        if( !this.form.operandUuid ) {
          this.handleInput()
        }
      });
    },

    selectClassificatorNode(item) {
      if(item.nodeType.name != 'TOPIC')
        this.selectOperandId(item.uuid, this.classificatorName(item))
      else
        this.form.operandUuid = null
    },

    searchClassificatorNode() {
        this.tree.loading = true
        this.findedNode = []
        let query = `?uuid=${this.currentClassificator.uuid}&text=${this.nodeSearch}`
        api.smeta.searchInClassificator(query).then((data) => {
          this.findedNode = data
          this.tree.loading = false
        })
    },

    submit(){
      this.$refs.valueForm.validate()
    },

    classificatorName (item) {
      return item.pressmark ? item.pressmark + " - " + item.title : item.title
    },

    createCondition() {
      this.setSearch(null)
      this.setValue(null)
      this.setManualOperand(null)
      let value = this.currentType == "STRING" ? "'" + this.form.value + "'" : this.form.value
      let currentOperandTitle = this.currentOperandTitle && this.currentOperandTitle != "" ? "'" + this.currentOperandTitle + "' " : ""
      let operatorTitle 
      if( this.form?.operator?.title ) operatorTitle = this.form.operator.title.toLowerCase()

      this.form.operandUuid = this.form.operand.name === 'MATERIALS' || this.form.operand.name === 'BASEATTR' ? null : this.form.operandUuid

      if (this.returnTitleName) {
        // let value = this.currentOperandTitle?.match(/\/(?<name>[^/]*?)$/)?.groups?.name
        let value = this.currentOperandTitle?.split('/')

        if(!value)
          value = this.currentOperandTitle

        this.form.title = value
      } else {
        if (this.isCollisionRule) {
          this.form.title = currentOperandTitle.trim().replace(/'/g, '')
        }

        if(this.simpleView) {
          if(this.isConst) {
            this.form.title=this.form.value
          }
          else {
            this.form.title=this.currentOperandTitle
          }
        } else {
          if(this.isAttr)
            if( (this.form.operator?.name=='EX' || this.form.operator?.name=='NOTEX') ) {
              this.form.title=currentOperandTitle+operatorTitle
            }
            else {
              this.form.title=currentOperandTitle+operatorTitle+" "+value
            }
          else if(!this.isAttr && (this.form.operator?.name=='CONT' || this.form.operator?.name=='NOTCONT')) {
            this.form.title=this.form.operand.title+" "+operatorTitle+" "+value
          }
          else {
            this.form.title=this.form.operand.title+" "+operatorTitle+" "+this.currentOperandTitle
          }
        }
      }

      if (this.copiedElementAttribute) this.setCopiedElementAttribute(null)

      if( this.isGridProp ) {
        this.$refs.valueForm.setData()
      } else {
        this.form.gridCondition = null
      }

      // Ручной ввод классификатора
      if( this.handleClassificator && this.handleClassification ) {
        if( this.handleClassificator?.trim() && this.handleClassification?.trim() ) {
          this.form.manualOperand = this.handleClassificator.trim() + '/' + this.handleClassification.trim()
          this.form.title = this.handleClassificator.trim() + " " + "равно" + " " + this.handleClassification.trim()
          this.form.operator = { value: "0", title: "Равно", name: "EQ" }
        }
      }

      // Выбор существующего классификатора
      if( this.isClassificator && !this.handleClassificator && !this.handleClassification) {
        return api.smeta.loadNodeLong(this.form.operandUuid).then((node) => {
          this.form.manualOperand = this.currentClassificator.title + '/' + node.pressmark

          if( this.parsedPressmark ) {
            this.form.manualOperand = this.currentClassificator.title + '/' + this.parsedPressmark
          }
          this.form.title = this.currentClassificator.title + ' ' + "равно" + ' ' + (node.pressmark ? node.pressmark + ' - ' + node.title : node.title)
          this.$emit('created', this.form)
        })
      }
      this.$emit('created', this.form)
      //projectService.saveCondition(cond).then(() => this.$emit('created', cond))
    },

    nodeSearchChange(value) {
      this.setNodeSearch(value)
      if (this.nodeSearchTimeout) {
        clearTimeout(this.nodeSearchTimeout)
      }
      this.nodeSearchTimeout = setTimeout(() => {
        if (this.nodeSearch && this.nodeSearch.trim() != "")
          this.searchClassificatorNode()
      }, 500)
    },

    maskChange() {
      if (this.maskTimeout) {
        clearTimeout(this.maskTimeout)
      }
      this.maskTimeout = setTimeout(() => {
        this.selectOperand(this.form.operand) 
      }, 500)
    },

    openSelectedClassificator() {
      if( this.pickedClassificator ) {
        for( let i = 0; i < this.pickedClassificator.length; i++ ) {
          if( this.form.manualOperand ) {
            if( this.pickedClassificator[i].title == this.form.manualOperand.split("/")[0] ) {
              this.selectClassificator( this.pickedClassificator[i] ).then((items) => {
                this.conditionTreeCache.setItems(items)
              })
            }
          }
        }
      }
    },

    async selectOperand(param, edit = false) {
      if (param.name === 'ATTRIBUTE'){
        this.activeAttributeTab = 'USERPROP'
        return
      }
      this.lastparameter = param
      this.setSearch( null )
      if(!edit) {
        this.form.operand = param
        this.form.operator = this.isCollisionRule ? this.form.operator : null
        // this.form.value = null
        this.form.operandUuid = null
        this.form.manualOperand = null
        if(this.simpleView) this.form.operator = simpleOperator
        if(this.isGridProp) this.cleanGridForm()
      }
      this.valueList = null
      this.valueListIteration++

      this.setLoaded(false)
      let currentDelimeter = this.form.operand.name=='MASK'? this.currentDelimeter : ""
      if(this.isClassificator) {
        this.listProjectClassificators()
        this.conditionTreeCache = new ConditionTreeCache()
      } else {
        conditionCache[this.projectUuid] ||= {}
        let data = (param.name !== 'MASK' && conditionCache[this.projectUuid][param.name]) || await (projectService.loadParamForCondition(this.projectUuid, param.name, currentDelimeter, this.hashProject).then(data => conditionCache[this.projectUuid][param.name] = data))
        // projectService.loadParamForCondition(this.projectUuid, param.name, currentDelimeter, this.hashProject).then(data => {
          if(param.name !== this.form.operand.name) {
            return
          }
          if (['MATERIALS', 'BASEATTR'].includes(param.name)) {
            data = data.map(item => ({ name: item, title: item, uuid: item }))
          }
          if (param.name === 'MANUALINPUT') {
            const [prefix, postfix] = this.form.manualOperand?.split('/') || []
            
            this.MANUALINPUT_prefix = prefix || ""
            this.MANUALINPUT_postfix = postfix || ""
          }
          if (this.isGeometry || this.isIdProp || this.isGridProp) {
            let pref = 'section.elementTree.' + ( this.isGeometry ? 'geometry.' : this.isIdProp ? 'idprop.' : 'gridprop.' ) 
            this.valueList = data.map(item => { 
              let it = Object.assign({}, item)
              it.name = this.$t(pref+item.attr)
              return it
            })
            if (this.isGridProp) { 
              this.LOAD_GRID()
              this.form.operator = this.condOperator.find(e => e.name == 'EQ')
            }
          } else {
              if (this.form.operand.name == "GROUP") {
                this.valueList = data.filter(group => group.shared && (this.groupUuid ? group.uuid !== this.groupUuid : true))
              } 
              else 
                this.valueList = data

            // if(this.form.operan  d.name == "GROUP" && this.groupUuid != null)
            //   this.valueList = data.filter(v => v.uuid != this.groupUuid)
            // else 
            //   this.valueList = data
          }
          if(this.form.operand.name === "MODEL"){
            this.valueList = data.map((item) => {
              const model = this.flatlist.find(e => e.uuid === item.uuid)
              const value = {...item}
              // value.name = value.title = model?.title
              value.name = value.title || model?.title
              if(!model){
                return null
              }
              return value
            }).filter(node => node)
          } 
          if(this.form.operand.name !== "CLASS") {
            let selectAttrUuid
            const foundItem = this.valueList.find(item => item.name === this.form.manualOperand)

            if (foundItem) {
              selectAttrUuid = foundItem.uuid
            }
            this.selectOperandId( selectAttrUuid, this.form.manualOperand )
            this.conditionTreeCache.setItems(this.valueList)

            try {
              // let selectedItem = this.form?.title.split("'")[1].split("/")
              // let currentItem = null
              // let items = this.conditionTreeCache?.tree?.items

              // selectedItem.forEach( item => {
              //   currentItem = items.find( i => i.name == item )
              //   if ( currentItem ) {
              //     this.selectAttr( currentItem, true )
              //     items = currentItem.children
              //   }
              // })

              for( let i = 0; i < this.conditionTreeCache?.tree?.items?.length; i++ ) {
                // TODO: Временное решение. Пересмотреть логику сохранения выделенных группировок

                try {
                  if( this.form?.title.split("'")[1].split("/")[0] == this.conditionTreeCache?.tree?.items[i].name ) {
                    this.selectAttr( this.conditionTreeCache.tree.items[i], true )
                  } 
                } catch {
                  if( ( ( this.form.manualOperand ) && ( this.form.manualOperand == this.conditionTreeCache.tree.items[i].name ) ) || ( this.form?.operandUuid == this.conditionTreeCache?.tree?.items[i].uuid ) ) {
                    this.selectAttr( this.conditionTreeCache.tree.items[i], true )
                  } 
                }
                try {
                  this.recursionFlag = false
                  try {
                    if( ( this.conditionTreeCache.tree.items[i].name == this.form?.manualOperand.split('/')[1].split('/')[0] ) ) {
                      this.selectAttr( this.conditionTreeCache.tree.items[i], true )
                      if( this.form.operand.name == "ATTRIBUTE" ) {
                        const startIndex = this.form.title.indexOf("'") + 1
                        const endIndex = this.form.title.indexOf("'", startIndex)
                        this.form.manualOperand = this.form.title.substring(startIndex, endIndex)
                        this.recursionPickedElement( this.conditionTreeCache.tree.items )
                      }
                    } else if( this.form.operand.name == "USERPROP" && !this.recursionFlag ) {
                      const startIndex = this.form.title.indexOf("'") + 1
                      const endIndex = this.form.title.indexOf("'", startIndex)
                      this.form.manualOperand = this.form.title
                      this.recursionPickedElement( this.conditionTreeCache.tree.items )
                      this.form.manualOperand = this.form.title.substring(startIndex, endIndex)
                    }
                  } catch {
                    if( this.form.operand.name == "USERPROP" && !this.recursionFlag ) {
                      const startIndex = this.form.title.indexOf("'") + 1
                      const endIndex = this.form.title.indexOf("'", startIndex)
                      this.form.manualOperand = this.form.title.substring(startIndex, endIndex)
                      this.recursionPickedElement( this.conditionTreeCache.tree.items )
                      this.form.manualOperand = this.form.title.substring(startIndex, endIndex)
                    }
                    let splitFormTitle = this.form?.title.split("/")
                    splitFormTitle = splitFormTitle[2].split(" ")
                    splitFormTitle = splitFormTitle[0].split("'")[0]

                    for( let j = 0; j < this.conditionTreeCache.tree.items.length; j++ ) {
                      if( this.conditionTreeCache.tree.items[j].name == this.form?.title.split('/')[1].split('/')[0] ) {
                        this.selectAttr( this.conditionTreeCache.tree.items[j], true )
                      } 
                      if( this.conditionTreeCache.tree.items[j].name == splitFormTitle ) {
                        this.selectAttr( this.conditionTreeCache.tree.items[j], true )
                      }
                    }
                  }
                } catch {
                  if ( this.conditionTreeCache.tree.items[i].title && this.conditionTreeCache.tree.items[i].title == this.form?.title.split("'")[1].split("/")[1] ) {
                    this.selectAttr( this.conditionTreeCache.tree.items[i], true )
                  }
                }
                if( this.conditionTreeCache.tree.items[i].name == this.form?.manualOperand.split('/').pop() ) {
                  // Вложенные в дерево элементы
                  if(this.conditionTreeCache.tree.items[i].children?.length) {
                    for( let j = 0; j < this.conditionTreeCache.tree.items[i].children.length; j++ ) {
                      if( this.form.title.split("/")[1].split("'")[0] == this.conditionTreeCache.tree.items[j].children.title ) {
                        this.selectAttr( this.conditionTreeCache.tree.items[j], true )
                      }
                    }
                  } else {
                    this.selectAttr( this.conditionTreeCache.tree.items[i], true )
                  }
                } 
              }

            } catch(e) {
              // let currentElement = this.conditionTreeCache?.tree?.items.find(item => { 
              //   return item?.title == this.form.manualOperand || item?.name == this.form.manualOperand ||  item?.title == this.form.value || item?.name == this.form.value
              // })
              // if(!currentElement?.type ?? null) {
              //   currentElement.importPickedElement = true
              // }
              // this.selectAttr( currentElement, true )


              if( selectAttrUuid && this.valueList && this.form.manualOperand ) {
                this.selectOperandId( selectAttrUuid, this.form.manualOperand )
                this.conditionTreeCache.setItems( this.valueList )
              }

              try {
                let formTitleIndex = this.form.title.split(' ').length
                this.form.manualOperand = this.form.title.split(' ').slice( formTitleIndex - 1 )[0]
                let indexOfOperator = this.form.title.indexOf( this.$t('section.elementTree.condition.EQ').toLowerCase() )
                let slicedTitle = this.form.title.slice( indexOfOperator + 6 )

                for( let i = 0; i < this.conditionTreeCache.tree.items.length; i++ ) {
                  if( this.conditionTreeCache.tree.items[i].name == this.form.manualOperand ) {
                    this.conditionTreeCache.tree.items[i].importPickedElement = true
                    this.selectAttr( this.conditionTreeCache.tree.items[i], true )
                  }

                  // Группировка
                  if( ( this.conditionTreeCache.tree.items[i].title == this.form.manualOperand ) || ( slicedTitle.toLowerCase() == this.conditionTreeCache.tree.items[i].title.toLowerCase() ) ) {
                    this.conditionTreeCache.tree.items[i].importPickedElement = true
                    this.selectAttr( this.conditionTreeCache.tree.items[i], true )
                  }
 
                  if( this.form.operand.name == "ATTRIBUTE" && !this.recursionFlag) {
                    if( this.reducedСonditions ) {
                      this.form.manualOperand = this.form.title
                    }
                    this.recursionPickedElement( this.conditionTreeCache.tree.items, true )
                  }
                }
              } catch {
                if( this.form.title ) { 
                  const startIndex = this.form.title.indexOf("'") + 1;
                  const endIndex = this.form.title.indexOf("'", startIndex);
                  const value = this.form.title.substring(startIndex, endIndex);

                  if (startIndex !== -1 && endIndex !== -1 && this.form.operand.name == "IDPROP") {
                    for( let i = 0; i < this.conditionTreeCache.tree.items.length; i++ ) {
                      if( this.conditionTreeCache.tree.items[i].name == value ) {
                        this.conditionTreeCache.tree.items[i].importPickedElement = true
                        this.selectAttr( this.conditionTreeCache.tree.items[i], true )
                      }
                    }
                  }
                } 
              }
            }
          }
          else {
            this.conditionTreeCache.setItems(this.valueList, true)
          }
          this.valueListIteration++
      
          // let openOperand = findById(this.condValues, this.form.operandUuid)
          let openOperand = findById(this.valueList, this.form.operandUuid)
          this.opens = openOperand.map(e => e.uuid)
           
          if (edit) { 
            this.currentOperandTitle=this.form.operandUuid ? this.pathTitle(this.form.operandUuid) : this.form.manualOperand
            this.restoreBeforeEdit(param, openOperand)
          }

          if( this.copiedElementAttribute?.name && this.copiedElementAttribute?.stringValue && ( this.form.operand.name == "IDPROP" || this.form.operand.name == "LAYER" ) ) {
            for( let i = 0; i < this.conditionTreeCache.tree.items.length; i++ ) {
               // if( ( this.conditionTreeCache.tree.items[i].name == this.copiedElementAttribute.name ) || ( this.conditionTreeCache.tree.items[i].name == this.copiedElementAttribute.stringValue ) ) {
              if (this.conditionTreeCache.tree.items[i].uuid === this.copiedElementAttribute.uuid) {
                this.selectAttr( this.conditionTreeCache.tree.items[i], true )
              }
            }
          }

          if( this.copiedElementAttribute?.name && !this.form.manualOperand ) {
            if( ( this.form.operand.name == "USERPROP" || this.form.operand.name == "ATTRIBUTE" ) && !this.reducedСonditions ) {
              this.recursionFlag = false
              this.form.value = this.copiedElementAttribute.stringValue || ''
              this.form.manualOperand = this.copyManualOperand
              this.recursionPickedElement( this.conditionTreeCache.tree.items, true )
              this.selectOperator( { name: "EQ", title: 'равно', value : 0 } )
              this.setSearch( this.copiedElementAttribute?.name )          
            } else if ( this.form.operand.name == "USERPROP" && this.reducedСonditions ) {
              this.recursionFlag = false
              this.form.value = null
              this.form.manualOperand = this.copyManualOperand
              this.recursionPickedElement( this.conditionTreeCache.tree.items, true )
              this.selectOperator( { name: "EX", title: this.$t('section.elementTree.condition.EX'), value : 8 } )
              this.setSearch( this.copiedElementAttribute?.name )   
            } else if ( this.form.operand.name == "ATTRIBUTE" && this.reducedСonditions ) {
              this.recursionFlag = false
              this.form.value = null
              this.form.manualOperand = this.copyManualOperand
              this.recursionPickedElement( this.conditionTreeCache.tree.items, true )
              this.selectOperator( { name: "EQ", title: 'равно', value : 0 } )
            } else if ( this.form.operand.name == "MODEL" ) {
              this.conditionTreeCache.tree.items.forEach( (e) => {
                e.name == this.form.value ? this.selectAttr( e, true ) : null
              })
            }
            this.setHandleClassificator( null )
            this.setHandleClassification( null )
          } 
          if( this.form.manualOperand && !this.reducedСonditions ) {
            let searchedValue = this.form.manualOperand.split("'")[1] ? this.form.manualOperand.split("'")[1] : this.form.manualOperand.split("/")
            if( searchedValue.includes("/") ) {
              searchedValue = searchedValue.replaceAll("'","").split('/')
              this.setSearch( searchedValue[searchedValue.length - 2] )
            } else {
              if(searchedValue.length >= 2) {
                this.setSearch( searchedValue[searchedValue.length - 2].replaceAll("'",""))
              } else {
                if (this.condition?.operand?.name != 'CONST') {
                  this.setSearch(searchedValue.toString()) // ['IFC'] -> 'IFC' //
                }
                if (this.form.operand.name === 'CLASS') {
                  this.searching = this.search
                }
              }
            }
          } else if(this.form.manualOperand && this.reducedСonditions) {
            let searchedValue = this.form.manualOperand
            if( searchedValue.includes("/") ) {
              searchedValue = searchedValue.split('/')
              this.setSearch( searchedValue[searchedValue.length - 2].replaceAll("'","") )
            } else { 
              if(this.condition?.operand?.name != "CONST"){
                this.setSearch( searchedValue.replaceAll("'","") )
              }
            }
          }
          this.setLoaded(true)
        // })
      }
    },

    restoreBeforeEdit(param, openOperand) {
      if(openOperand.length>0){
        if(this.isAttr && !['BASEATTR'].includes(this.form.operand.name))
          this.selectAttr(openOperand[openOperand.length-1], true)
        else { 
          if( this.form.operand.name !== "GROUP" ) {
            this.currentOperandTitle=this.pathStartTitle(openOperand[openOperand.length-1].uuid)
          } else {
            this.currentOperandTitle = this.form.manualOperand
          }
        }
      }
    },
    selectOperandId(attr, title) {
      if( ( this.form.operand.name=='MASK' || this.form.operand.name=='CLASS' ) && title ) {
        this.form.manualOperand = title.substring(title.indexOf("/")+1)
      }
      else if ( this.form.operand.name == "GROUP" )  {
        this.form.manualOperand = title
        this.currentOperandTitle = this.form.manualOperand
      } 
      else if (this.form.operand.name === 'IDPROP' || this.form.operand.name === 'GRIDPROP' || this.form.operand.name === 'GEOMETRY') {
        this.form.manualOperand = attr ?? this.form.operandUuid
      }
      else {
        this.form.manualOperand = title
        this.currentOperandTitle = this.form.manualOperand
      }
      this.form.operandUuid = attr ?? this.form.operandUuid
      if( this.form.operand.name !== "GROUP" ) {
        this.parsedPressmark = title ? ( title.includes(' ') ? title.split(' ')[0] : null ) : null
        this.currentOperandTitle = title
      }
    },

    pathTitle(id) {
      if(this.isGeometry || this.isIdProp || this.isGridProp)
        return this.pathStartTitle(id)
      else
        return findById(this.valueList, id).reduce((path, cur) => { return path += (path != "" ? "/" : "") +cur.name}, "")
    },

    pathStartTitle(id) {
      let path = findById(this.valueList, id)
      if(path.length>1) return path[0].name+"/"+path[path.length-1].name
      if(path.length==1) return path[0].name
      else return ""
    },

    selectAttr(item, edit = false) {
      this.recursionFlag = false
      if(!edit) {
        this.form.value = null
        this.form.operandUuid = null
      }
      if(item?.type && item?.type?.name != "SET" && item?.type?.name != "CLASS" && item?.type?.name != "CLASSIFICATOR"){
        // INT LONG STRING DOUBLE DATE BOOL ENUM
        this.setCurrentType( item.type.name )
        //! TODO: нужно проверить и удалить👇
        // this.attrUnit = item.defaultUnit.unitName 
        if(this.currentType == "ENUM") projectService.loadBClassEnumElements(item.uuid).then(data => this.attrTypeEnum = data)
        if(this.currentType == "BOOL") this.attrTypeEnum = [{"value":"true", "text":this.$t('section.elementTree.yes')}, {"value":"false", "text":this.$t('section.elementTree.no')}]
        this.selectOperandId(item.uuid, this.pathTitle(item.uuid))
      } else if ( item.importPickedElement ) {
        if( this.pathTitle(item.uuid) == "undefined" || !this.pathTitle(item.uuid) ) {
          this.selectOperandId(item.uuid, item.title)
          this.currentOperandTitle = this.form.manualOperand
        } else {
          this.selectOperandId(item.uuid, this.pathTitle(item.uuid))
        }
        this.handleTreeOpen(item)
      } else {
        if (item.uuid) {
          this.selectOperandId(item.uuid, this.pathTitle(item.uuid))
        }
      }

      this.handleTreeOpen(item)
    },

    selectValue(value) {
      this.form.value = value
    }, 

    async updateForm() {
      if(this.form.operand.name != ""){
        if (this.isClassificator && !this.form.operandUuid && this.form.manualOperand) {
          const clsf = this.projectClassificators.find(clsf => clsf.title === this.form.manualOperand.split('/')[0])
          const [node] = await api.smeta.searchInClassificatorOnlyByPressmark(`?uuid=${clsf.uuid}&text=${this.form.manualOperand.split('/')[1]}`)
          this.form.operandUuid = node.uuid
        }
        if(this.isClassificator && this.form.operandUuid) {
          api.smeta.loadNodeLong(this.form.operandUuid).then(async (node) => {
            this.findedNode = [node]
            this.setSearchMode( true )

            if (!this.projectClassificators || this.projectClassificators.length == 0) {
              api.smeta.projClassListForChoice(this.projectUuid).then(async (classificators) => {
                this.setProjectClassificators(classificators)
                this.selectClassificator(this.projectClassificators.find(el => el.uuid == node.projectClassificatorUuid))
                await this.selectOperand(this.form.operand, true)
                this.setNodeSearch( node.pressmark )
              });
            } else {
              this.selectClassificator(this.projectClassificators.find(el => el.uuid == node.projectClassificatorUuid))
              await this.selectOperand(this.form.operand, true)
              this.setNodeSearch( node.pressmark )
            }
          })
        } else 
          await this.selectOperand(this.form.operand, true)
      } else if (this.copiedElementAttribute) {
        let topLevel = this.copiedElementAttribute.topLevel ? this.copiedElementAttribute.topLevel : false
        let operand = this.copiedElementAttribute.operand ? this.copiedElementAttribute.operand : 'USERPROP'
        
        if(smetaPanelStore.tab == 2 && operand === "IDPROP"){
          this.form.operand = null
        }
        else {
          this.form.operand = this.axisEnum.find(e => e.name == operand)
        }
        if (operand === 'MATERIALS' || operand === "FLOOR" || operand === 'SYSTEMS') {
          this.form.manualOperand = this.copiedElementAttribute.stringValue
        }
        
        this.form.operandUuid = topLevel ? this.copiedElementAttribute.uuid : this.copiedElementAttribute.elementClass

        if(this.form.operand){
          if (this.simpleView)  this.form.operator = this.condOperator.find(e => e.name == 'EX')
          else this.form.operator = this.condOperator.find(e => e.name == 'EQ')
          this.form.value = this.copiedElementAttribute.stringValue
          this.form.manualOperand = this.form.operand.name === "CLASS" ? this.form.value : this.form.manualOperand
          await this.selectOperand(this.form.operand, true)
          if (operand === 'MATERIALS' || operand === "FLOOR") {
            this.condValues.find(item => item.name === this.form.manualOperand) ? "" : this.form.operator = this.condOperator.find(operator => operator.name === 'CONT')
          }
        } else {
          await this.selectOperand(this.axisEnum[0])
        }
        
      }
      else if (this.lastparameter) {
        await this.selectOperand(this.lastparameter)
      }
       else if (this.axisFilter && this.axisFilter.length==1) {
        await this.selectOperand(this.axisEnum[0])
      }

    },

    handleTreeOpen(item) {
      if(this.conditionTreeCache.isOpened(item)) {
        this.conditionTreeCache.close(item)
      }
      else {
        this.conditionTreeCache.open(item)
      }
    },

    importTreeOpen(item) {
      this.conditionTreeCache.open(item)
    },

    itemName(item) {
      return (item.name && item.name!='') ? item.name : (item.title && item.title!='') ? item.title : (item.classTitle && item.classTitle!='') ? item.classTitle : this.$t('section.elementTree.name')
    },

    setMaskAsFormOperand() {
      const maskaxis = this.axisEnum.find(axis => axis.name === 'MASK')
      this.form.operand = maskaxis
    },
  },
}
</script>

<style lang="scss" scoped>
.h-100 {
  height:100%;
  max-height:300px;
  min-height:300px;
  overflow-y: hidden;
  overflow-x: hidden;
}
.maxw {
  max-width:369px;
}
.select-maxw {
  max-width:197px;
}
.hover-title {
  top:20px;
  left:5px;
  padding: 2px;
  width:328px;
  position:absolute;
  background:#EEE;
  white-space: normal;
  overflow-wrap: break-word;
  z-index:1;
  border-radius:5px;
  border:1px solid #CCC;
}
._tree-item {
  font: normal 12px/16px "Roboto", sans-serif;
  color: black;
  white-space: nowrap;
}
.item__toggle {
  font-size: 16px;

  &--open {
    transform: none;
  }

  &--close {
    transform: rotate(-90deg);
  }
}
.item-level {
  min-width: 8px
}
</style>
<style scoped>
.three-dropdown .v-list  {
  width: 355px !important;
}
.tooltip {
  position: fixed;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 10;
  white-space: nowrap;
}
.three-dropdown .v-list {
  width: 355px !important;
}
:first-child>>>.v-tabs-bar {
  height: 24px;
}
:first-child>>>.v-tab {
  text-transform: none; 
}
._tree-item { 
  font: normal 12px/16px "Roboto", sans-serif;
  color: black;
  transition: .1s;
  white-space: nowrap;
  text-overflow: revert-layer;
  overflow: hidden;
  
}
._tree-item:hover {
  background: #f0f0f0;
}
.item__toggle {
  font-size: 16px;
}
.item__toggle--open {
  transform: none;
}
.item__toggle--close {
  transform: rotate(-90deg);
}

.tab-active {
  position: relative;
}
.tab-active:after {
  content: "";
  height: 2px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: currentColor;
  position: absolute;
}

:first-child >>> .v-input__slot {
  min-height: 36px !important;
}
</style>
<template lang="pug">
.d-flex.handleSearch( style="white-space: nowrap;" )
  v-col( v-if="searchClassificator" style="width: 250px;" )
    app-text-field( v-model="valueSearchClassificator" :label="$t('section.elementTree.condition.CLASSIFICATOR')" clearable )

  div
    .vl

  v-col( v-if="searchClassificator" style="width: 250px;" ) 
    app-text-field( v-model="valueSearchClassification" :label="$t('section.classification.default')" clearable )

</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex' 

export default {
  name: 'ConditionHandleClassificator',

  props: {
    conditionTreeCache: null,
  },

  data() {
    return {
      splitManualOperand: null,
      valueSearchClassificator: this.handleClassification,
      valueSearchClassification: this.valueSearchClassification,
    }
  },

  watch: {
    valueSearchClassificator: function () {
      this.setHandleClassificator( this.valueSearchClassificator ) 
    },

    valueSearchClassification: function () {
      this.setHandleClassification( this.valueSearchClassification )
    },

    form: function () {
      this.takeTextfield()
    },
  },

  computed: {
    ...mapState('condition', ['searchClassificator', 'currentClassificator', 'nodeSearch', 'form', 'handleClassificator', 'handleClassification']),
    ...mapState('smeta', ['projectClassificators']),
    ...mapGetters('condition', ['activeClass']),
  },

  beforeMount() {
    if( this.form.title ) {
      let splitedTitle = this.form.title.split(" " + this.$t('section.elementTree.condition.EQ').toLowerCase() + " ")
      this.valueSearchClassificator = splitedTitle[0]
      this.valueSearchClassification = splitedTitle[1]
    }
  },

  mounted() {
    this.takeTextfield()
    this.form.manualInput = true
  },

  methods: {
    ...mapActions('condition', ['selectClassificator', 'loadChildrens']),
    ...mapMutations('condition', ['setCurrentClassificator', 'setSearchClassificator', 'setHandleClassificator', 'setHandleClassification']),

    takeTextfield() {
      if( this.form.manualOperand ) {
        this.splitManualOperand = this.form.manualOperand.split("/")
        this.valueSearchClassificator = this.splitManualOperand[0]
        this.valueSearchClassification = this.splitManualOperand[1]
      }
    },
  }
}

</script>

<style scoped>
.vl {
  margin-top: -30px;
  border-left: 1px solid;
  border-color:#666666;
  height: 430px;
}
.handleSearch {
  margin-left: -12px; 
  margin-right: -12px;
  margin-top: -12px;  
}
</style>

<template lang="pug">
div
  app-text( v-for="item in condOperatorEnum" :key="item.value" test-id="collision-condition-comparison")
    div( :style="form.operator && form.operator.name == item.name ? activeClass : ''" @click="selectOperator(item)" ) {{ $t(`section.elementTree.condition.${item.name}`) }}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: "ConditionComparison",
  props: {
    operatorFilter: Array
  },
  computed: {
    ...mapState('condition', ['form']),
    ...mapGetters('condition', ['activeClass', 'condOperator']),

    condOperatorEnum() {
      if (this.operatorFilter && this.operatorFilter.length > 0) return this.condOperator.filter(a => this.operatorFilter.includes(a.name))
      return this.condOperator
    },

  },

  methods: {
    ...mapActions('condition', ['selectOperator']),
  }
}
</script>

<style>

</style>
<template lang="pug">
div
  app-text( v-for="item in projectClassificators" :key="item.uuid"  )
    div( :style="( currentClassificator && currentClassificator.uuid == item.uuid ) && ( !form.manualInput ) ? activeClass : ''" @click="handleSelect(item)" test-id="condition-classifier") {{ item.title }}
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
export default {
  name: "ConditionClassificator",

  props: {
    conditionTreeCache: null
  },

  computed: {
    ...mapState('smeta', ['projectClassificators']),
    ...mapState('condition', ['currentClassificator', 'tree', 'searchClassificator', 'handleClassification', 'form']),
    ...mapGetters('condition', ['activeClass']),
  },

  mounted() {
    this.form.manualInput = false
    if(this.projectClassificators.some(el => el.projectUuid == this.currentClassificator?.projectUuid)){
      this.conditionTreeCache.setItems(this.tree.items)
    }
  },

  methods: {
    ...mapActions('condition', ['selectClassificator']),
    ...mapMutations('condition', ['setSearchClassificator', 'setHandleClassificator', 'setHandleClassification', 'setNodeSearch', 'setLoaded']),

    handleSelect(item) {
      this.setNodeSearch(null)
      this.form.manualInput = false
      this.setSearchClassificator( false )
      this.setHandleClassificator( null ) 
      this.setHandleClassification( null ) 
      this.selectClassificator(item).then((items) => {
        this.conditionTreeCache.setItems(items)
        this.setLoaded(true)
      })
    }
  }
}
</script>

<style>

</style>
export const $_validate_rules = {
  data () {
    return {
      rules: {
        required: value => (!!value && !!(value?.trim())) || 'Обязательно к заполнению.', 
        requiredSimple: value => (!!value) || 'Обязательно к заполнению.',
        requiredcombo: value => (!!value && value.length>0) || 'Обязательно к заполнению.',
        number: value => {
          const pattern = /^[0-9]+$/
          return pattern.test(value) || 'Допустимы только цифры.'
        },
        text: value => {
          const pattern = /^[A-Za-zа-яА-ЯёЁ0-9.-]+$/
          return pattern.test(value) || 'Допустимы только цифры, буквы, "." или "-"'
        },
        double: value => {
          const pattern = /^(-?)([0-9]\d*(\.)\d*|[0-9]\d*)$/
          return pattern.test(value) || 'Допустимы только цифры.'
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Некорректный e-mail.'
        },
        emailcombo: value => {
          if (!value || !value.length) {
            return true
          }
          
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          let res = true

          for (let index = 0; index < value.length; index++) {
            const element = value[index];
            const data = element instanceof Object ? element.data : element
            res = res && pattern.test(data)
          }
          return res || 'Некорректный e-mail.'
        },
      }
    }
  }
}

